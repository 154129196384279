export default {
  zeroxEthereum: 'https://api.0x.org',
  zeroxBsc: 'https://bsc.api.0x.org',
  zeroxPolygon: 'https://polygon.api.0x.org',
  zeroxFantom: 'https://fantom.api.0x.org',
  zeroxAvalanche: 'https://avalanche.api.0x.org',
  zeroxArbitrum: 'https://arbitrum.api.0x.org',
  oneinch: 'https://api.1inch.exchange/v3.0',
  limitOrders: 'https://limit-orders.1inch.exchange/v1.0',
  horizon: 'https://horizon.stellar.org',
  blocktrail: 'https://api.blocktrail.com/v1/BTC',
  bitpay: [
  'https://api.bitcore.io/api/BTC/mainnet',
    //'https://insight.bitpay.com/api',
    //'https://invoices.swaponline.site/proxybitpay',
  ],
  ghostscan: 'https://ghostscan.io/ghost-insight-api',
  nextscan: 'https://explore.next.exchange/#',
  nextExplorer: 'https://next.swaponline.io/mainnet',
  nextExplorerCustom: 'https://next.swaponline.io/mainnet',
  blockcypher: 'https://api.blockcypher.com/v1/btc/main',
  etherscan: 'https://api.etherscan.io/api',
  etherscanInvoices: 'https://invoices.onout.org/proxyetherscan',
  etherscan_ApiKey: 'GK6YHJ5NMEF67R4FTRNQS2EK3HRBP5VVHW',
  bscscan: 'https://api.bscscan.com/api',
  bscscan_ApiKey: 'WI4QEJSV19U3TF2H1DPQ2HR6712HW4MYKJ',
  maticscan: 'https://api.polygonscan.com/api',
  polygon_ApiKey: '8S2R45ZWG94HI7YK9RCXSK4VCASJ4XVA15',
  ftmscan: 'https://api.ftmscan.com/api',
  ftm_ApiKey: 'J39MXI2KQ7YWFR3JGYHPVYK1MIH132QSXP',
  avaxscan: 'https://snowtrace.io/api',
  avax_ApiKey: 'BEDYVGMKPM4HXIVD16B1Z66D5R75D9AHNC',
  movrscan: 'https://api-moonriver.moonscan.io/api',
  movr_ApiKey: 'VHG8YAQMA78MAQKU7C73Z4UQ2A83S4IBGW',
  onescan: '',
  one_ApiKey: '',
  amescan: '',
  ame_ApiKey: '',
  aurorascan: 'https://api.aurorascan.dev/api',
  aurora_ApiKey: 'J9ZZ9C6FI4YHJVISBI2VYRRJ1MTU3ID45Q',
  faucet: 'https://faucet.onout.org/mainnet/', // deprecated
  btc2FAProtected: 'https://2fa.onout.org',
  btcPin: 'https://2fapin.onout.org',
  invoiceApi: 'https://invoices.onout.org',
  multisig: 'https://multisig.onout.org',
  txinfo: 'https://txinfo.onout.org',
  noxon: 'https://noxon.wpmix.net',
  phiscan: 'https://phiscan.com/api',
  fkwscan: 'https://explorer.fokawa.com/api',
  phpxscan: 'https://explorer.phpx.network/api',
  WalletConnectProjectId: 'a23677c4af3139b4eccb52981f76ad94',
}
